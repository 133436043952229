import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseInputComponent } from '@ui/base-input.component';
import { NgClass, NgIf } from '@angular/common';
import { IconDirective } from '../icon/icon.directive';
import { InputType } from '@dto/commons/input';

@Component({
  selector: 'ng-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  imports: [
    NgClass,
    NgIf,
    IconDirective
  ],
  standalone: true
})
export class InputComponent<T extends InputType> extends BaseInputComponent<T> implements AfterViewInit {

  @ViewChild('el') element!: ElementRef;

  @Input() label = '';
  @Input() height = 'fit-content';

  @Input() password = false;
  @Input() override error = false;
  @Input() dataList: T|string[] = [];
  @Input() floatingIcon?: string | undefined;
  @Input() placeholder = '';
  @Input() labelStyle = '';

  ngAfterViewInit(): void {
    this.prevDisplay = this.valueObject.display;
  }
}
