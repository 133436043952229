<label class="w-full input" [ngClass]="(disabled?'disabled ':'') + (type==='textarea'?'area':'')">
  <input
    #el
    title=""
    *ngIf="type !== 'textarea'"
    [id]="id||label"
    [name]="id||label"
    [ngClass]="error && _touched ? 'err' : ''"
    [attr.val]="valueObject.display"
    [type]="password ? 'password' : 'text'"
    [required]="required"
    [placeholder]="placeholder"
    (blur)="handleBlur()"
    (keydown.enter)="handleEnter($event)"
    [value]="valueObject.display"
    autocomplete="one-time-code"
    (input)="handleChange($event, 'input')"
  >
  <textarea
    *ngIf="type === 'textarea'"
    [style.height]="height"
    [style.min-height]="height"
    [id]="id||label"
    [ngClass]="error && _touched ? 'err' : ''"
    [attr.val]="valueObject.display || placeholder"
    [placeholder]="placeholder"
    [required]="required"
    (blur)="handleBlur()"
    (keydown.enter)="handleEnter($event)"
    [value]="valueObject.display"
    (input)="handleChange($event, 'input')"
  ></textarea>
  <span *ngIf="label" [style]='labelStyle'>
    <div [innerText]="label" class="inline"></div>
    <div *ngIf="required" class="req inline ml-0.5">*</div>
  </span>
  <svg *ngIf="floatingIcon" [icon]="floatingIcon || ''"></svg>

  <!--  (ngModelChange)="handleChange($event, 'modelChange')"-->
  <!--  (input)="handleChange($event, 'input')"-->
</label>
