import { Region } from '../dto/commons/Region';

const CountryCodeMap: Record<Region, string> = {
  [Region.BELARUS]: '+375',
  [Region.RUSSIA]: '+7',
  [Region.POLAND]: '+48',
  [Region.GEORGIA]: '+995',
  [Region.LITHUANIA]: '+370'
};

const PhoneMaskMap: Partial<Record<Region, RegExp>> = {
  [Region.BELARUS]: /(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/,
  [Region.RUSSIA]: /(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/,
}

export const phoneTransformer = {
  normalize: (p: string): string => {
    let result = p?.replace(/\D/g,'')?.trim() || '';
    if (result.length > 12) {
      result = result.replace('375375', '375').substring(0, 12);
    }
    return result;
  },

  defineRegion: (p: string, fromInput = false): Region | undefined => {
    if (!p) {
      return;
    }
    let phone = phoneTransformer.normalize(p);
    if (!fromInput && phone.length === 9) {
      return Region.BELARUS;
    }

    for (let region of Object.keys(CountryCodeMap)) {
      const code = CountryCodeMap[region as Region].replace('+', '');
      if (phone.startsWith(code)) {
        return region as Region;
      }
    }
    return;
  },

  toString: (p: string, fromInput = false): string => {
    if (!fromInput && p?.length === 9) {
      return phoneTransformer._toString(p, Region.BELARUS);
    }
    const region = phoneTransformer.defineRegion(p, fromInput);
    return phoneTransformer._toString(p, region);
  },

  _toString: (p: string, region?: Region): string => {
    const code = region && CountryCodeMap[region];
    const mask = region && PhoneMaskMap[region];
    if (code && mask) {
      return phoneTransformer.toStringMask(p, code, mask);
    }

    let normalized = phoneTransformer.normalize(p);
    if (code) {
      normalized = normalized.replace(
        code.replace('+', ''),
        ''
      );
      return normalized.length ? `${code} ${normalized}` : code;
    }

    return `+${normalized}`;
  },

  toStringMask: (phone: string, code: string, mask: RegExp): string => {
    if (!phone) {
      return '';
    }
    let p = phoneTransformer.normalize(phone);
    const codeCheck = code.replace('+', '');
    if (p.startsWith(codeCheck)) {
      p = p.substring(codeCheck.length);
    }
    const parts = p.match(mask);

    let result = code;
    if (!parts || parts.length < 2) {
      return result;
    }
    if (parts[1]) result += ' (' + parts[1];
    if (parts[2]) result += ') ' + parts[2];
    for (let i = 3; i < parts.length; i++) {
      if (parts[i]) {
        result += '-' + parts[i];
      } else {
        break;
      }
    }
    return result;
  },

  toBelarusSMSFormat: (p: string): string => {
    p = p.replace(/\D/g,'').trim();
    if (!p.startsWith('375')) {
      p = '375' + p;
    }
    if (p.length !== 12) {
      throw new Error('Invalid phone formatting: ' + p);
    }
    return p;
  },

  toSmsFormat(p: string, region: Region) {
    if (region === Region.BELARUS) {
      return phoneTransformer.toBelarusSMSFormat(p);
    }
    return p.replace(/\D/g,'').trim();
  },

  getCode(r: Region): string | undefined {
    return CountryCodeMap[r];
  }
}
