import { DateUtils } from '@utils/date.utils';
import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { phoneTransformer } from '@utils/phone.transformer';
import { InputType, InputValue, InputValueTypeMap } from '@dto/commons/input';
import { StringUtils } from '@utils/string.utils';
import { Region } from '@dto/commons/Region';

@Directive()
export abstract class BaseInputComponent<T extends InputType> {

  abstract element: ElementRef;

  public readonly valueObject: InputValue<T> = { display: '' };
  @Input() type: T = 'text' as T;
  @Input() phoneRegion?: Region;

  error = false;
  @Input() required = true;
  _touched = false;
  @Input() set touched(t: any) {
    this._touched = !!t || this._touched;
  }
  @Input() validateOnInit = false;
  @Input() next?: string;
  @Input() id?: string;
  @Input() maxLength: number = 1000;

  @Input() disabled = false;

  @Output() valueChange: EventEmitter<InputValueTypeMap[T]> = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() enter = new EventEmitter();
  protected firstCheck = true;


  @Input() set value(val: InputValueTypeMap[T] | undefined) {
    this.valueObject.value = val;
    if (this.type === 'time') {
      this.valueObject.display = DateUtils.toStringTime(val as any);
    }
    else if (this.type === 'year' || this.type === 'int') {
      this.valueObject.display = String(val);
      if (isNaN(parseInt(this.valueObject.display))) {
        this.valueObject.display = ''
      }
    }
    else if (typeof val === 'string') {
      this.valueObject.display = val;
    }
    else {
      console.warn('unknown input type for object: ', val);
    }
    // console.log('input display: ', this.valueObject.display);
    this.prevDisplay = this.valueObject.display;
    if (this.required || this.validateOnInit) {
      this.validate(this.valueObject.display);
    }
  }

  handleEnter(event: any): void {
    this._touched = true;
    this.enter.emit();
    if (this.next?.length) {
      event.preventDefault();
      document.getElementById(this.next)?.focus();
    }
    this.element?.nativeElement?.blur();
  }

  handleBlur(): void {
    this._touched = true;
    if (!this.validateOnInit) {
      this.validate(this.valueObject.display);
    }
    this.blur.emit();
  }

  protected prevDisplay = '';

  protected validate(value: string): void {
    if (this.type === 'time') {

      if (value.length > this.prevDisplay.length) {
        const lastChar = value.charAt(value.length - 1);
        if (!/^\d+$/.test(lastChar) && lastChar !== ':') {
          value = this.prevDisplay;
        }
      }
      if (value.length > 5) {
        value = this.prevDisplay;
      }
      if (value.length === 2 && this.prevDisplay.length < 2) {
        value += ':'
      }
      if (value.endsWith(':') && value.replace(':', '').includes(':')) {
        value = this.prevDisplay;
      }
      this.valueObject.display = value;
      this.error = !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);

      if (!this.error) {
        this.valueObject.value = DateUtils.getTimeFromString(this.valueObject.display) as any;
      }
    }

    else if (this.type === 'email') {
      this.valueObject.display = this.valueObject.value = value.trim() as any;
      this.error = !StringUtils.isEmailValid(value.trim());
    }

    else if (this.type === 'phone') {
      let isEmpty = false;
      if (this.phoneRegion) {
        const code = phoneTransformer.getCode(this.phoneRegion);
        const currentValue = this.valueObject.display || code || "";
        if (currentValue === code && value.length < code.length && code.startsWith(value)) {
          this.valueObject.display = value = code;
          isEmpty = true;
        }
      }

      value = phoneTransformer.normalize(value);

      if (!isEmpty) {
        this.valueObject.display = this.phoneRegion
          ? phoneTransformer._toString(value, this.phoneRegion)
          : phoneTransformer.toString(value, !this.firstCheck);
      }
      if (this.firstCheck && value?.length) {
        this.firstCheck = false;
      }

      this.error = !value || value.length < 9;
      this.valueObject.value = value as any;
      // console.log('validate result: ', value);
    }

    else if (this.type === 'year' || this.type === 'int') {
      if (
        (value && !/^\d+$/.test(value)) ||
        (this.type === 'year' && value.length > 4)
      ) {
        value = this.prevDisplay;
      }
      this.error = !/^\d+$/.test(value) || (this.type === 'year' && value.length !== 4);
      if (!this.error) {
        this.valueObject.value = parseInt(value) as any;
      }
      this.valueObject.display = value;
    }

    else {
      if (value.length > this.maxLength) {
        value = value.substring(0, this.maxLength - 1);
      }
      this.valueObject.display = value;
      this.error = this.required && (!value || value.length < 2);

      // console.log('value: ', value);
      // console.log('required: ', this.required);
      // console.log('err: ', this.error);

      this.valueObject.value = value as any;
    }

    this.prevDisplay = this.valueObject.display;
  }

  handleChange(event: unknown, label = 'event'): void {
    // console.log('handleChange [' + label + ']: ' + (event as any)?.target?.value || event);
    if (typeof event === 'string') {
      this.validate(event);
    } else {
      this.validate((event as any).target.value);
      (event as any).target.value = this.valueObject.display;
    }
    if (this.type !== 'time' || !this.error) {
      this.valueChange.emit(this.valueObject.value);
    }
  }
}
